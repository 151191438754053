.work {
    width: 100%;
    background: #F5F5F5;

    .work-content {
        width: 100%;
        padding-left: 228px;
        padding-bottom: 128px;
        padding-right: 275px;

        .titlem {
            width: 100%;
            text-align: center;
            margin-bottom: 126px;

            h6 {
                color: var(--dark-blue, #143A62);

                font-family: "Avenir Next LT Pro";
                font-size: 76px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -3.04px;
            }
        }

        .workmain-content {
            display: flex;
            width: 100%;

            .workleft {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-block: 50px;

                .title {

                    max-width: 781px;
                    margin-bottom: 69px;

                    h6 {
                        color: #2B2B2B;

                        font-family: "Avenir Next LT Pro";
                        font-size: 64px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -2.56px;
                    }
                }

                .title-bottom {
                    .bottom-contnt {
                        max-width: 540px;
                        // height: 63.567px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

            }

            .workright {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .data-section {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    position: relative;

                    .top-section {
                        display: flex;
                        align-items: center;
                        gap: 28px;
                        position: relative;
                        z-index: 2;

                        .right {
                            .title {
                                h6 {
                                    color: #2B2B2B;

                                    font-family: "Avenir Next LT Pro";
                                    font-size: 32px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    letter-spacing: -1.28px;
                                }
                            }

                            .subtitle {
                                max-width: 514px;
                                margin-top: 20px;

                                p {
                                    color: #707070;

                                    font-family: "Avenir Next LT Pro";
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: -0.72px;
                                }
                            }
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 32px;
                        top: 20px;
                        height: calc(100% - 30px);
                        width: 100%;
                        border-left: 3px dashed #143A62;

                        z-index: 1;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .work {
        width: 100%;

        .work-content {
            width: 100%;
            padding-left: 60px;
            padding-bottom: 128px;
            padding-right: 60px;

            .titlem {
                width: 100%;
                text-align: center;
                margin-bottom: 126px;

                h6 {
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -3.04px;
                }
            }

            .workmain-content {
                display: flex;
                width: 100%;

                .workleft {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-block: 50px;

                    .title {

                        max-width: 781px;
                        margin-bottom: 69px;

                        h6 {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -2.56px;
                        }
                    }

                    .title-bottom {
                        .bottom-contnt {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                }

                .workright {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .data-section {
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        position: relative;

                        .top-section {
                            display: flex;
                            align-items: center;
                            gap: 28px;

                            .right {
                                .title {
                                    h6 {
                                        color: #2B2B2B;

                                        font-family: "Avenir Next LT Pro";
                                        font-size: 25px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;
                                        letter-spacing: -1.28px;
                                    }
                                }

                                .subtitle {
                                    max-width: 514px;
                                    margin-top: 20px;

                                    p {
                                        color: #707070;

                                        font-family: "Avenir Next LT Pro";
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: -0.72px;
                                    }
                                }
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            left: 32px;
                            top: 20px;
                            height: calc(100% - 30px);
                            width: 100%;
                            border-left: 3px dashed #143A62;

                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .work {
        width: 100%;

        .work-content {
            width: 100%;
            padding-left: 60px;
            padding-bottom: 128px;
            padding-right: 60px;

            .titlem {
                width: 100%;
                text-align: center;
                margin-bottom: 126px;

                h6 {
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -3.04px;
                }
            }

            .workmain-content {
                display: flex;
                width: 100%;

                .workleft {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-block: 50px;

                    .title {

                        max-width: 781px;
                        margin-bottom: 69px;

                        h6 {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -2.56px;
                        }
                    }

                    .title-bottom {
                        .bottom-contnt {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                }

                .workright {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .data-section {
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        position: relative;

                        .top-section {
                            display: flex;
                            align-items: center;
                            gap: 28px;

                            .right {
                                .title {
                                    h6 {
                                        color: #2B2B2B;

                                        font-family: "Avenir Next LT Pro";
                                        font-size: 25px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;
                                        letter-spacing: -1.28px;
                                    }
                                }

                                .subtitle {
                                    max-width: 514px;
                                    margin-top: 20px;

                                    p {
                                        color: #707070;

                                        font-family: "Avenir Next LT Pro";
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: -0.72px;
                                    }
                                }
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            left: 32px;
                            top: 30px;
                            height: calc(100% - 100px);
                            width: 100%;
                            border-left: 3px dashed #143A62;

                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .work {
        width: 100%;

        .work-content {
            width: 100%;
            padding-left: 10px;
            padding-bottom: 50px;
            padding-right: 10px;

            .titlem {
                width: 100%;
                text-align: center;
                margin-bottom: 50px;

                h6 {
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.04px;
                }
            }

            .workmain-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .workleft {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-block: 10px;

                    .title {

                        width: 100%;
                        margin-bottom: 20px;
                        text-align: center;

                        h6 {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.1px;
                        }
                    }

                    .title-bottom {
                        .bottom-contnt {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                }

                .workright {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .data-section {
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        position: relative;
                        gap: 20px;

                        .top-section {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .left {

                                // width: 50px;
                                img {
                                    // width: 50px;
                                }
                            }

                            .right {
                                width: 80%;

                                .title {
                                    h6 {
                                        color: #2B2B2B;

                                        font-family: "Avenir Next LT Pro";
                                        font-size: 20px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;
                                        letter-spacing: -1.28px;
                                    }
                                }

                                .subtitle {
                                    width: 100%;
                                    margin-top: 5px;

                                    p {
                                        color: #707070;

                                        font-family: "Avenir Next LT Pro";
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: -0.72px;
                                    }
                                }
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            left: 30px;
                            top: 30px;
                            height: calc(100% - 50px);
                            width: 1%;
                            border-left: 3px dashed #143A62;

                            z-index: 1;
                            // border: 1px solid red;
                        }
                    }
                }
            }
        }
    }
}