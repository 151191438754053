.navbar-section {
    padding: 0px 133px 0px 133px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #F5F5F5;
    // background: rgb(219, 196, 196);
    height: 100px;

    // height: calc(100vh - 90vh);
    .navbar-main {
        width: 100%;
        height: 100%;

        // background-color: aqua;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .navbar-middle {
            position: relative;

            ul {


                display: flex;
                gap: 85px;


                li {
                    color: #5B5B5B;
                    font-family: "Avenir Next LT Pro";
                    font-size: 21.333px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    .options {
                        display: flex;
                        align-items: center;
                    }

                    .div {
                        background: #F5F5F5;
                        width: 200px;
                        // height: 100px;
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        padding: 10px;
                        top: 50px;
                        border-radius: 10px;


                        // top: 50px;

                        span {
                            color: #5B5B5B;
                            font-family: "Avenir Next LT Pro";
                            font-size: 17.333px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .navbar-right {
            button {
                padding: 16px 32px;
                border-radius: 8px;
                background: var(--dark-blue, #143A62);
                color: #FFF;
                font-family: "Avenir Next LT Pro";
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                border: none;
                cursor: pointer;
            }
        }

        .navbar-right-toggle {
            display: none;
        }

        .navbar-middle,
        .navbar-right {
            // display: none;

        }

        .navbar-middle.show,
        .navbar-right.show {
            // display: block;
        }

    }

}

@media screen and (max-width: 1600px) {
    .navbar-section {
        padding: 0px 20px 0px 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background: #F5F5F5;
        // background: rgb(219, 196, 196);
        height: 100px;

        // height: calc(100vh - 90vh);
        .navbar-main {
            width: 100%;
            height: 100%;

            // background-color: aqua;

            // .navbar-primary {
            //     width: 100%;
            //     height: 100%;
            padding: 0px;

            .navbar-middle {
                ul {
                    display: flex;
                    gap: 40px !important;

                    .li {


                        .nav-link {
                            color: #5B5B5B;
                            font-family: "Avenir Next LT Pro";
                            font-size: 21.333px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                }
            }

            .navbar-right {
                // margin-left: 170px;
                display: flex;
                gap: 16px;

                button {
                    padding: 16px 32px;
                    border-radius: 8px;
                    border-radius: 8px;
                    background: var(--dark-blue, #143A62);
                    color: #FFF;
                    font-family: "Avenir Next LT Pro";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    cursor: pointer;



                    // &:hover {
                    //     color: white;
                    //     border-radius: 8px;
                    //     background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));

                    // }

                    // &::before {

                    //     content: "";
                    //     width: 100%;
                    //     height: 20px;
                    //     position: absolute;
                    //     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
                    //     bottom: -10px;
                    //     left: 0;
                    //     opacity: 0.5;
                    //     background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                    //     filter: blur(15px);

                    // }
                }


            }
        }
    }

}

// }

@media screen and (max-width: 1350px) {
    .navbar-section {
        padding: 0px 10px 0px 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background: #F5F5F5;
        // background: rgb(219, 196, 196);
        height: 100px;

        .navbar-main {
            width: 100%;
            height: 100%;

            // background-color: aqua;
            display: flex;
            justify-content: space-between;
            align-items: center;

            // .navbar-primary {
            //     width: 100%;
            //     height: 100%;
            //     padding: 0px;

            //     .nav-ul {
            //         display: flex;
            //         gap: 85px;

            //         .nav-item {


            //             .nav-link {
            //                 color: #5B5B5B;
            //                 font-family: "Avenir Next LT Pro";
            //                 font-size: 21.333px;
            //                 font-style: normal;
            //                 font-weight: 500;
            //                 line-height: normal;
            //                 text-transform: capitalize;
            //             }
            //         }

            //     }

            //     .form-search {
            //         // margin-left: 170px;
            //         display: flex;
            //         gap: 16px;

            //         .sign-up {
            //             padding: 16px 32px;
            //             border-radius: 8px;
            //             border-radius: 8px;
            //             background: var(--dark-blue, #143A62);
            //             color: #FFF;
            //             font-family: "Avenir Next LT Pro";
            //             font-size: 22px;
            //             font-style: normal;
            //             font-weight: 500;
            //             line-height: normal;
            //             text-transform: capitalize;
            //             cursor: pointer;



            //             // &:hover {
            //             //     color: white;
            //             //     border-radius: 8px;
            //             //     background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));

            //             // }

            //             // &::before {

            //             //     content: "";
            //             //     width: 100%;
            //             //     height: 20px;
            //             //     position: absolute;
            //             //     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
            //             //     bottom: -10px;
            //             //     left: 0;
            //             //     opacity: 0.5;
            //             //     background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
            //             //     filter: blur(15px);

            //             // }
            //         }


            //     }
            // }
            .navbar-middle {
                ul {


                    display: flex;
                    gap: 15px !important;

                    li {
                        color: #5B5B5B;
                        font-family: "Avenir Next LT Pro";
                        font-size: 17.333px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;

                        .options {

                            .dropdown-icon {
                                width: 30px;
                            }
                        }
                    }
                }
            }

            .navbar-right {
                button {
                    padding: 10px 25px;
                    border-radius: 8px;
                    background: var(--dark-blue, #143A62);
                    color: #FFF;
                    font-family: "Avenir Next LT Pro";
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    border: none;
                }
            }
        }


    }
}

@media only screen and (max-width: 600px) {
    .navbar-section {
        padding: 0px 10px 0px 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background: #F5F5F5;
        // background: white;

        // background: rgb(219, 196, 196);
        height: 100px;

        // height: calc(100vh - 90vh);
        // .navbar-main {
        //     position: relative;
        //     /* Added */
        //     width: 100%;
        //     height: 100%;

        //     background-color: #FAFDFF;


        //     .navbar-primary {
        //         background-color: #FAFDFF;

        //         .navbar-brand {
        //             position: absolute;
        //             /* Adjusted */
        //             top: 50%;
        //             /* Adjusted */
        //             left: 0px;
        //             /* Adjusted */
        //             transform: translateY(-50%);
        //             /* Adjusted */
        //         }

        //         .navbar-toggler {
        //             position: absolute;
        //             /* Adjusted */
        //             top: 50%;
        //             /* Adjusted */
        //             right: 0px;
        //             /* Adjusted */
        //             transform: translateY(-50%);
        //             /* Adjusted */
        //             outline: 1px solid black;
        //             box-shadow: none;
        //         }

        //         .nav-ul {

        //             gap: 10px;
        //             margin-top: 70px;
        //             background-color: #FAFDFF;
        //             padding-bottom: 10px;



        //             .nav-item {

        //                 .nav-link {

        //                     font-size: 20px;

        //                 }
        //             }

        //         }

        //         .form-search {
        //             margin-left: 0px;
        //             flex-direction: column;
        //             gap: 10px;
        //             padding-bottom: 10px;
        //             padding-top: 10px;


        //             background-color: #FAFDFF;

        //             margin-top: -10px;

        //             .sign-up {

        //                 font-size: 20px;


        //             }

        //             .Log-in {

        //                 font-size: 20px;

        //                 padding: 16px 38px;





        //             }
        //         }
        //     }
        // }
        .navbar-main {
            width: 100%;
            height: 100%;
            padding-top: 10px;
            background: #F5F5F5;

            // background-color: aqua;
            display: flex;
            justify-content: start;
            flex-direction: column;
            align-items: start;


            .navbar-right-toggle {
                display: block;
            }

            .navbar-middle.show,
            .navbar-right.show {
                display: block;
            }

            .not {
                display: none;
            }

            .navbar-left {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .navbar-middle {
                background: #F5F5F5;
                width: 100%;
                padding-top: 20px;
                padding-left: 10px;


                ul {

                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    li {
                        color: #5B5B5B;
                        font-family: "Avenir Next LT Pro";
                        font-size: 17.333px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;

                        .options {
                            display: flex;
                            align-items: center;
                        }

                        .div {
                            background: #F5F5F5;
                            width: 200px;
                            // height: 100px;
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            padding: 10px;
                            top: 170px;

                            // top: 50px;

                            span {
                                color: #5B5B5B;
                                font-family: "Avenir Next LT Pro";
                                font-size: 17.333px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .navbar-right {
                background: #F5F5F5;
                width: 100%;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 10px;

                button {
                    padding: 10px 25px;
                    border-radius: 8px;
                    background: var(--dark-blue, #143A62);
                    color: #FFF;
                    font-family: "Avenir Next LT Pro";
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    border: none;
                }
            }
        }

    }
}