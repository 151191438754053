.powerdata {
    width: 100%;

    .powerdata-content {
        width: 100%;
        background-image: url("../../assets/images/power-data.png");
        height: 600px;
        background-size: cover;
        // object-fit: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 228px;

        .content-main {
            max-width: 781px;

            .title {
                h6 {
                    color: #FFF;

                    font-family: "Avenir Next LT Pro";
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -2.56px;
                }
            }

            .subtitle {
                margin-top: 44px;

                p {
                    color: #FFF;

                    font-family: "Avenir Next LT Pro";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .data-btn {
                display: flex;
                gap: 32px;
                margin-top: 31px;

                .learn-more {
                    padding: 16px 32px;
                    border-radius: 8px;
                    background: var(--dark-blue, #143A62);
                    color: #FFF;
                    font-family: "Avenir Next LT Pro";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    border: none;
                }

                .signup-more {
                    padding: 16px 32px;
                    border-radius: 8px;
                    border: 1.333px solid var(--dark-blue, #143A62);
                    background: #FAFDFF;
                    color: var(--dark-blue, #143A62);
                    font-family: "Avenir Next LT Pro";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    border: none;
                }

            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .powerdata {
        width: 100%;

        .powerdata-content {
            width: 100%;
            background-image: url("../../assets/images/power-data.png");
            height: 600px;
            background-size: cover;
            // object-fit: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 60px;

            .content-main {
                max-width: 781px;

                .title {
                    h6 {
                        color: #FFF;

                        font-family: "Avenir Next LT Pro";
                        font-size: 50px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -2.56px;
                    }
                }

                .subtitle {
                    margin-top: 44px;

                    p {
                        color: #FFF;

                        font-family: "Avenir Next LT Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }
                }

                .data-btn {
                    display: flex;
                    gap: 32px;
                    margin-top: 31px;

                    .learn-more {
                        padding: 16px 32px;
                        border-radius: 8px;
                        background: var(--dark-blue, #143A62);
                        color: #FFF;
                        font-family: "Avenir Next LT Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                    }

                    .signup-more {
                        padding: 16px 32px;
                        border-radius: 8px;
                        border: 1.333px solid var(--dark-blue, #143A62);
                        background: #FAFDFF;
                        color: var(--dark-blue, #143A62);
                        font-family: "Avenir Next LT Pro";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .powerdata {
        width: 100%;

        .powerdata-content {
            width: 100%;
            background: #143A62;


            height: 300px;
            background-size: cover;
            // object-fit: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .content-main {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    h6 {
                        color: #FFF;

                        font-family: "Avenir Next LT Pro";
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.1px;
                    }
                }

                .subtitle {
                    margin-top: 20px;
                    text-align: center;

                    p {
                        color: #FFF;

                        font-family: "Avenir Next LT Pro";
                        font-size: 19px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.1px;
                    }
                }

                .data-btn {
                    display: flex;
                    gap: 20px;
                    margin-top: 20px;

                    .learn-more {
                        padding: 10px 20px;
                        border-radius: 8px;
                        background: var(--dark-blue, #143A62);
                        color: #FFF;
                        font-family: "Avenir Next LT Pro";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                        border: 1px solid white;
                    }

                    .signup-more {
                        padding: 10px 20px;
                        border-radius: 8px;
                        border: 1.333px solid var(--dark-blue, #143A62);
                        background: #FAFDFF;
                        color: var(--dark-blue, #143A62);
                        font-family: "Avenir Next LT Pro";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                    }

                }
            }
        }
    }
}