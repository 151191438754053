.footer {
    width: 100%;

    // height: 125px;
    .footer-top {
        height: 3px;
        background: linear-gradient(90deg, rgba(20, 58, 98, 0.00) 0%, #101C42 51%, rgba(20, 58, 98, 0.00) 100%);
    }

    .footer-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 234px;
        padding-right: 227px;
        padding-top: 46px;
        padding-bottom: 25px;

        .middle {
            span {
                color: #707070;

                font-family: "Avenir Next LT Pro";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.72px;
            }
        }

        .rightside {
            display: flex;
            gap: 32px;

            svg {
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .footer {
        width: 100%;

        // height: 125px;
        .footer-top {
            height: 3px;
            background: linear-gradient(90deg, rgba(20, 58, 98, 0.00) 0%, #101C42 51%, rgba(20, 58, 98, 0.00) 100%);
        }

        .footer-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 60px;
            padding-right: 60px;
            padding-top: 46px;
            padding-bottom: 25px;

            .middle {
                span {
                    color: #707070;

                    font-family: "Avenir Next LT Pro";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.72px;
                }
            }

            .rightside {
                display: flex;
                gap: 32px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .footer {
        width: 100%;

        // height: 125px;
        .footer-top {
            height: 3px;
            background: linear-gradient(90deg, rgba(20, 58, 98, 0.00) 0%, #101C42 51%, rgba(20, 58, 98, 0.00) 100%);
        }

        .footer-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 30px;
            padding-bottom: 30px;
            gap: 20px;

            .middle {
                span {
                    color: #707070;

                    font-family: "Avenir Next LT Pro";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.2px;
                }
            }

            .rightside {
                display: flex;
                gap: 32px;

                svg {
                    width: 30px;
                }
            }
        }
    }
}