.team-section {
    .team-content {
        padding: 176px 100px 239px 100px;

        .title {
            width: 100%;
            text-align: center;
            margin-bottom: 103px;

            h6 {
                color: var(--dark-blue, #143A62);
                font-family: "Avenir Next LT Pro";
                font-size: 76px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -3.04px;
                margin-bottom: 28px;
            }

            span {
                color: #707070;
                font-family: "Inter";
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.88px;
            }
        }

        .team-card {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 170px;
        }
    }
}

@media only screen and (max-width: 1850px) {
    .team-section {
        .team-content {
            padding: 176px 60px 239px 60px;

            .title {
                width: 100%;
                text-align: center;
                margin-bottom: 103px;

                h6 {
                    color: var(--dark-blue, #143A62);
                    font-family: "Avenir Next LT Pro";
                    font-size: 76px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -3.04px;
                    margin-bottom: 28px;
                }

                span {
                    color: #707070;
                    font-family: "Inter";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .team-card {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 170px;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .team-section {
        .team-content {
            padding: 176px 60px 239px 60px;

            .title {
                width: 100%;
                text-align: center;
                margin-bottom: 103px;

                h6 {
                    color: var(--dark-blue, #143A62);
                    font-family: "Avenir Next LT Pro";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -3.04px;
                    margin-bottom: 28px;
                }

                span {
                    color: #707070;
                    font-family: "Inter";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .team-card {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 50px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .team-section {
        .team-content {
            padding: 50px 10px 50px 10px;

            .title {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;

                h6 {
                    color: var(--dark-blue, #143A62);
                    font-family: "Avenir Next LT Pro";
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.1px;
                    margin-bottom: 10px;
                }

                span {
                    color: #707070;
                    font-family: "Inter";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .team-card {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 40px;
            }
        }
    }
}