.uploadFile-main-container {
    padding: 20px;
    height: calc(100vh - 13%);

    .uploadFile-mainCentered-container {
        height: fit-content;
        padding: 15px;
        background-color: #f1f1f1;
        border-radius: 10px;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        overflow: scroll;
        // border: 1px solid red;

        .head-tabs {
            display: flex;

            // gap: 5px;
            button {
                text-align: center;
                width: 190px;
                // border: 1px solid red;
                padding: 8px 20px;
                color: #FFF;
                font-weight: 500;
                background-color: #15ADDC;
                white-space: nowrap;
            }

            .active-tab {
                background-color: #1081a3;
            }
        }

        .innerTabs-box {
            // border: 1px solid red;

            button {
                text-align: center;
                width: 190px;
                padding: 8px 20px;
                color: #FFF;
                font-weight: 500;
                background-color: #073a49;
                white-space: nowrap;
            }

            .active-tab {
                background-color: #104e61;
            }
        }

        .innerTabs-box-analysis {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: fit-content;
            // border: 1px solid red;
            button {
                width: 285px;
            }
        }

        .subtab1-box {
            height: auto;
            padding: 0px 0px 30px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid rgb(209, 209, 209);
        }

        .checkstructured-btn-container {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgb(209, 209, 209);
            // border: 1px solid red;
        }

    }
}

// .NonSturctured-btn-div {}

.skipUpload {
    color: #262626;
    text-align: right;
    width: 100%;
    color: #104e61;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 20px 0px 0px;
    // border: 1px solid red;
}

.sss {
    padding: 20px;
    width: 568px;
}