
/* .dataResults-container {
    border: 1px solid red;
    overflow-x: auto;
} */
.audit-sheet-container{
    padding: 0px 20px;
    /* margin-top: 10px; */
    background-color: #fff;
    width: 82vw;
    /* border-radius: 10px; */
}
.audit-h1{
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0px 20px 0px;
}
.audit-h1 span{
    color: #3d7a9b;
}

.dataResults-container h1{
        font-size: 30px;
        font-weight: 600;
        padding: 20px 0px 20px 0px;
}
.dataResults-container h1 span{
    color: #3d7a9b;
}
.dataResults-align{
    display: flex;
    align-items: center;
    gap: 60px;
}
.dataResults-align button{
    height: 50px;
    padding: 0px 30px;

}

.ant-table-wrapper .ant-table-thead>tr>th {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
    transition: background 0.2s, border-color 0.2s;
    border-bottom: 1px solid #f0f0f0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Table Custom Scroll */
.ant-table {
    max-width: 100%;
    overflow: auto;
}

.ant-table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.ant-table::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
    border-radius: 6px;
}

.ant-table::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.ant-table::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* Table Custom Scroll */


.repoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 80px;
}

.guideContainer{
    padding: 50px;
    border-radius: 5px;
    border: 1px solid black;
}

.guideContainer h1{
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
}

.links{
    margin-top: 10px;
}

.links ul{
    list-style-type: disc;
    
}

.links ul a{
    color: blue;
}