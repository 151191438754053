.loginContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #16ADDC;
    .loginBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 72%;
        border: 1px solid white;
        border-radius: 7px;
        padding: 40px;
        width: 32%;
        background-color: white;
        
        .headers{
            display: flex;
            justify-content: center;
            img{
                // width: 60%;
                width: 200px;
                    object-fit: cover;
            }
        }
        .loginInputContainer{
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
width: 100%;
.descriptionBox{
width: 100%;
margin-bottom: 10px;
h1{
    font-size: 22px;
    font-weight: 700;
}
    p{
        font-size: 16px;
        font-weight: 400;
        align-self: flex-start;
        span{
            color: #16ADDC;
            font-weight: 500;
        }
    }
}
.loginInput{
width: 100%;
    input{
        width: 100%;
        border-radius: 5px;
    }
    
}
button{
    background-color: #16ADDC;
    color: white;
    padding: 7px 20px;
    border-radius: 5px;
    margin-top: 10px;
}

        }
    }
}

@media screen and (min-height: 650px) {
    .loginContainer{
        .loginBox{
            height: 60%;
            width: 30%;
            .headers{
                img{
                    // width: 50%;
                }
            }
        }
    }    
}
@media screen and (min-height: 720px) {
    .loginContainer{
        .loginBox{
            height: 50%;
            width: 30%;
            .headers{
                img{
                    // width: 50%;
                }
            }
        }
    }    
}

@media screen and (max-width: 1300px) {
    .loginContainer{
        .loginBox{
            // height: 60%;
            width: 35%;
            .headers{
                img{
                    // width: 60%;
                }
            }
        }
    }    
}
@media screen and (max-width: 1000px) {
    .loginContainer{
        .loginBox{
            // height: 60%;
            width: 45%;
            .headers{
                img{
                    // width: 60%;
                }
            }
        }
    }    
}
@media screen and (max-width: 850px) {
    .loginContainer{
        .loginBox{
            // height: 62%;
            width: 55%;
            .headers{
                img{
                    // width: 70%;
                }
            }
        }
    }    
}
@media screen and (max-width: 700px) {









































    
    .loginContainer{
        .loginBox{
            // height: 55%;
            width: 90%;
            .headers{
                img{
                    // width: 200px;
                    // object-fit: cover;
                }
            }
        }
    }    
}
@media screen and (max-width: 450px) {
    .loginContainer{
        .loginBox{
            padding: 20px;
            // height: 50%;
        }
    }    
}