.whychoose-section {
    width: 100%;
    background: #F5F5F5;

    .choose-content {
        width: 100%;
        padding: 0px 229px 70px 229px;

        .title {
            width: 100%;
            margin-bottom: 107px;

            h6 {
                text-align: center;
                color: var(--dark-blue, #143A62);

                font-family: "Avenir Next LT Pro";
                font-size: 76px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -3.04px;
            }
        }

        .choose-main {
            display: flex;
            width: 100%;

            .choose-left {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .left-title {
                    max-width: 623px;
                    margin-top: 38px;

                    h6 {


                        color: #2B2B2B;

                        font-family: "Avenir Next LT Pro";
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -1.92px;
                    }
                }

                .left-subtitle {
                    max-width: 623px;
                    margin-top: 47px;

                    h6 {


                        color: #707070;

                        font-family: "Avenir Next LT Pro";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }
                }
            }

            .choose-right {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .right-content {
                    max-width: 715px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .whychoose-section {
        width: 100%;

        .choose-content {
            width: 100%;
            padding: 0px 60px 70px 60px;

            .title {
                width: 100%;
                margin-bottom: 107px;

                h6 {
                    text-align: center;
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -3.04px;
                }
            }

            .choose-main {
                display: flex;
                gap: 10px;
                width: 100%;

                .choose-left {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .left-title {
                        max-width: 623px;
                        margin-top: 38px;

                        h6 {


                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -1.92px;
                        }
                    }

                    .left-subtitle {
                        width: 100%;
                        margin-top: 47px;

                        h6 {


                            color: #707070;

                            font-family: "Avenir Next LT Pro";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.88px;
                        }
                    }
                }

                .choose-right {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .right-content {
                        max-width: 715px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .whychoose-section {
        width: 100%;

        .choose-content {
            width: 100%;
            padding: 0px 10px 50px 10px;

            .title {
                width: 100%;
                margin-bottom: 50px;

                h6 {
                    text-align: center;
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.1px;
                }
            }

            .choose-main {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                .choose-left {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    .left-logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 50px;
                        }
                    }

                    .left-title {
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h6 {


                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.2px;
                        }
                    }

                    .left-subtitle {
                        width: 100%;
                        margin-top: 20px;

                        h6 {


                            color: #707070;

                            font-family: "Avenir Next LT Pro";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.2px;
                        }
                    }
                }

                .choose-right {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .right-content {
                        width: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}