.chatbotContainer {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10;
  .chatbotMain {
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid #57AEDC;
    border-radius: 5px;
    background: linear-gradient(45deg, transparent 49%, rgba(87, 174, 220, 0.3) 49% 51%, transparent 51%) , linear-gradient(-45deg, transparent 49%, rgba(87, 174, 220, 0.3) 49% 51%, transparent 51%);
    background-size: 3em 3em;
        background-color: #ffffff;
        opacity: 1;
    .chatbotHeader {
      padding: 10px 15px;
      background-color: #57AEDC;
      color: white;
      border-bottom: 1px solid #57AEDC;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 11%;
      h2 {
        font-size: 20px;
        font-weight: 600;
      }
      .crossBtn{
        display: flex;
        cursor: pointer;
        align-items: center;
      }
    }
    .chatbotMessageBox {
      padding: 15px 10px;
      height: 89%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      
      .messages {
        height: 90%;
        // background-color: aqua;
        // margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        .message{
            display: flex;
            flex-direction: column;

        }
        p{
            padding: 8px 10px;
            border-radius: 5px;
    font-weight: 600;
    

        }
        .user{

            flex-direction: row-reverse;
p{

    background-color: rgb(194, 194, 194);
    
}



        }
        .ai{
            p{

                background-color: #8bd2f8;
            }
        }
      }
      .messageInput {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border: 1px solid #57AEDC;
        border-radius: 5px;
        height: 10%;
        background-color: #ffffff;
        input {
          width: 90%;
          height: 100%;
          border: none;
        //   &:focus{
        //     outline: none !important;
        //     border: none !important;
        //   }
        }
      }
    }
  }
  .chatbotIcon{
    position: absolute;
    bottom: 0;
    right: 0;
    // background-color: rgba(87, 174, 220, 0.3);
    cursor: pointer;
  }
}
.messages{

.promptSuggestions{
  height: 70%;
  width: calc(100% - 20px);
  background-color: #57AEDC;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  // left: 0;
bottom: 11%;  
// transform: translateX(50%);
  h3{
    font-size: 20px;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  .prompt{
    padding: 5px;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
    cursor: pointer;
&:hover{
  background-color: white;
  color: #57AEDC;
}

    
  }
}
}