@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @font-face {
  font-family: Campton;
  src: url("/src/assets/fonts/CamptonBold.otf");
} */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@font-face {
  font-family: Campton;
  src: url("/src/assets/fonts/CamptonMedium.otf");
}
@font-face {
  font-family: Campton-light;
  src: url("/src/assets/fonts/CamptonLight.otf");
}
@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("/src/assets/fonts/AvenirLTProLight.otf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  /* outline: 1px solid hotpink; */
}

.profile .img {
  background: var(--grad, linear-gradient(90deg, #143a62 0%, #15addc 100%));
}
.sign_up {
  background: linear-gradient(
    107deg,
    #143a62 -0.52%,
    rgba(40, 75, 112, 0.8) 22.1%,
    rgba(210, 244, 255, 0.81) 52.06%,
    rgba(53, 184, 225, 0.8) 79.39%,
    #15addc 100%
  );
}

.input input::placeholder {
  font-family: Campton;
}

.Campton {
  font-family: Campton;
}
.Campton-light {
  font-family: Campton-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: var(--primary, #15addc);
}

.primary {
  color: #15addc;
}

::-webkit-scrollbar {
  display: none;
}

.custom_height {
  height: calc(100vh - 138px);
  overflow: auto;
}
.custom_height::-webkit-scrollbar {
  display: none;
}

.dashboard_main {
  /* display: inline-block; */
  /* height: 200px;
  width: 200px; */
  margin: 20px;
  border-radius: 25px;
  border: 2px dashed #fff;
  background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(90deg, #143a62 0%, #15addc 100%) border-box;
  transition: transform 0.5s ease;
}

/* linear-gradient(90deg, #143A62 0%, #15ADDC 100%)) */

.dashboard_main:hover {
  border: none;
  border-radius: 25.316px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 16px 0px rgba(21, 173, 220, 0.4);
  transform: scale(1.02);
  transition: transform 0.5s ease;
}
.save {
  background: var(--grad, linear-gradient(90deg, #143a62 0%, #15addc 100%));
}

.modal_btn_hover {
  background: #143a62;
}
.btn_hover {
  background: linear-gradient(
    90deg,
    #15addc 0.03%,
    #143a62 50.14%,
    #15addc 99.97%
  );
  transition: all 0.3s ease;
}

.btn_hover:hover {
  background: linear-gradient(
    90deg,
    #143a62 0.08%,
    #15addc 52.12%,
    #143a62 99.97%
  );
  transition: all 0.3s ease;
}
.h1-color {
  background: linear-gradient(89deg, #d76e3e 22.62%, #6b2b4b 98.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Campton;
}
.item_bg {
  background: linear-gradient(
    270deg,
    #416122 -3.03%,
    #50742c 43.71%,
    #6e973e 144.87%,
    #50742c 186.51%
  );
}
.clip_path {
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0% 100%);
}
.gradient-border {
  position: relative;
}
.gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  padding: 2px;
  background: linear-gradient(90deg, #143a62 0%, #15addc 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.profile_name {
  border-radius: 9.677px 9.677px 0px 0px;
  border-bottom: 0.283px solid rgba(0, 0, 0, 0.2);
}

.edit {
  border-radius: 0px 0px 9.677px 9.677px;
  border-bottom: 0.283px solid rgba(0, 0, 0, 0.2);
  background: rgba(21, 173, 220, 0.2);
}

.btn_bg_gradient {
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #15addc 0.03%,
    #143a62 50.14%,
    #15addc 99.97%
  );
  color: white;
  text-align: center;

  cursor: pointer;
}
.btn_bg_gradient:hover {
  background: linear-gradient(
    90deg,
    #143a62 0.08%,
    #15addc 52.12%,
    #143a62 99.97%
  );
}

.Rubik {
  font-family: Rubik;
}
.dashboard-modal {
  height: unset;
}

.large2 {
  /* width: 863px; */
  border-radius: 50px;
  padding-bottom: 70px;
  padding-left: 50px;
  padding-right: 50px;
  background: var(--White, #fff);
}

.large {
  padding-left: 50px;
  padding-right: 40px;
  padding-bottom: 50px;
}
.relative {
  height: auto;
  /* width: 700px; */
  border-radius: 25px;
  border: none !important;

  padding: 0;
  border-radius: 45.943px;
  background: var(--White, #fff);
  /* height: 100% !important; */
  /* box-shadow: 0px 4.594px 40.2px 0px rgba(0, 0, 0, 0.08); */

  outline: none;
}

.heading {
  color: var(--primary-dark, #143a62);
}

.signup_heading {
  font-family: Campton;
}

.bill {
  border-radius: 12.909px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 12.909px 0px rgba(0, 0, 0, 0.1);
}
.tab {
  position: relative;
}

.tab::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 100%;
  left: 0;
  border-radius: 0 8px 8px 0;
  background: #15addc;
  z-index: -1;
  transition: 0.5s ease-in-out;
}

.tab:hover::before {
  opacity: 1 !important;
  width: 100%;
}
.tab:hover li {
  opacity: 1;
}

.activetab {
  background-color: #15addc !important;
  color: white;
  opacity: 1;
}

.activetab li {
  opacity: 1;
  color: #fff;
}
.activetab svg {
  opacity: 1;
  color: #fff;
}

select::placeholder {
}
select option {
  opacity: 0.4;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.tab:hover svg {
  color: #fff;
  opacity: 1;
}

.recommended {
  position: relative;
}

.sidebarshow {
  transform: translateX(0);
  transition: transform 0.5s ease;
}

.recommended_top {
  background: linear-gradient(97deg, #2b5322 1.71%, #467537 99.58%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
}

.recommended_top h2 {
  background: linear-gradient(265deg, #2b5322 -4.09%, #467537 90.18%);
  height: 76px;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
}

.setting {
  border-radius: 20px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

.name {
  border-radius: 9.677px 9.677px 0px 0px;
  border-bottom: 0.283px solid rgba(0, 0, 0, 0.2);
}

.dropdown1 {
  border-radius: 9.677px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 19.355px 0px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 700px) {
  .relative {
    width: 100% !important;
  }

  .large {
    padding: 20px !important;
  }
  .dashboard-modal {
    padding: 20px;
  }
  .relative {
    width: 100% !important;
    /* background-color: #d76e3e; */
    /* padding: 0 20px !important; */
    border-radius: 24px;
  }
}
@media screen and (max-width: 1500px) {
  .gradient-border::before {
    border-radius: 10px;
  }
}
