.corefeature {
    position: relative;
    background: #F5F5F5;

    .corefeature-content {
        padding-left: 229px;
        padding-right: 247px;
        padding-top: 121px;
        // padding-bottom: 211px;
        padding-bottom: 100px;

        .title {
            width: 100%;
            padding-bottom: 107px;

            h6 {
                text-align: center;
                color: var(--dark-blue, #143A62);

                font-family: "Avenir Next LT Pro";
                font-size: 76px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -3.04px;
            }
        }

        .featureContent-a {
            display: flex;
            gap: 70px;
            margin-bottom: 60px;

            .featureleft-content {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .leftcontent {
                    max-width: 677px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .featureright-content {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .rightcontent {
                    max-width: 724px;

                    .title-right {
                        h6 {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -1.92px;
                        }
                    }

                    .subtitle-right {
                        margin-top: 34px;

                        p {
                            color: #707070;

                            font-family: "Avenir Next LT Pro";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.88px;
                        }
                    }

                }
            }
        }
    }

    .herotopimg {
        position: absolute;
        top: 0;
        left: 0;
        // right: 0;
        width: 12.3%;
        height: 100%;
        background-repeat: no-repeat;
        z-index: 1;

    }

    .herobottomimg {
        position: absolute;
        top: 0;
        // bottom: -60%;
        z-index: 10;
        right: 0;
        z-index: 1;
        height: 100%;
        // width: 12.3%;



    }
}

@media only screen and (max-width: 1600px) {
    .corefeature {
        position: relative;

        .corefeature-content {
            padding-left: 60px;
            padding-right: 60px;
            padding-top: 100px;
            padding-bottom: 100px;

            .title {
                width: 100%;
                padding-bottom: 107px;

                h6 {
                    text-align: center;
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -3.04px;
                }
            }

            .featureContent-a {
                display: flex;
                margin-bottom: 60px;
                gap: 50px;

                .featureleft-content {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .leftcontent {
                        max-width: 677px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .featureright-content {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .rightcontent {
                        max-width: 724px;

                        .title-right {
                            h6 {
                                color: #2B2B2B;

                                font-family: "Avenir Next LT Pro";
                                font-size: 40px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: -1.92px;
                            }
                        }

                        .subtitle-right {
                            margin-top: 24px;

                            h6 {
                                color: #707070;

                                font-family: "Avenir Next LT Pro";
                                font-size: 21px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.88px;
                            }
                        }

                    }
                }
            }
        }

        .herotopimg {
            position: absolute;
            top: 0;
            left: 0;
            // right: 0;
            width: 7%;
            height: 100%;
            background-repeat: no-repeat;
            z-index: 1;

        }

        .herobottomimg {
            position: absolute;
            top: 0;
            // bottom: -60%;
            z-index: 10;
            right: 0;
            z-index: 1;
            height: 100%;
            width: 7%;



        }
    }
}

@media only screen and (max-width: 600px) {
    .corefeature {
        position: relative;

        .corefeature-content {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 50px;
            padding-bottom: 50px;

            .title {
                width: 100%;
                padding-bottom: 50px;

                h6 {
                    text-align: center;
                    color: var(--dark-blue, #143A62);

                    font-family: "Avenir Next LT Pro";
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.04px;
                }
            }

            .featureContent-a {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                gap: 20px;

                .featureleft-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    order: 2;

                    .leftcontent {
                        width: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .featureright-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-block: 20px;

                    .rightcontent {
                        width: 100%;
                        text-align: center;

                        .title-right {
                            h6 {
                                color: #2B2B2B;

                                font-family: "Avenir Next LT Pro";
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: -0.12px;
                            }
                        }

                        .subtitle-right {
                            margin-top: 10px;

                            p {
                                color: #707070;

                                font-family: "Avenir Next LT Pro";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.12px;
                            }
                        }

                    }
                }
            }
        }

        .herotopimg {
            display: none;



        }

        .herobottomimg {
            display: none;



        }
    }
}