.teamcard {
    // width: 333px;
    width: 400px;

    .teamcard-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card-title {
            margin-top: 29px;

            h6 {
                color: #2B2B2B;

                font-family: "Readex Pro";
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.88px;
            }
        }

        .card-subtitle {
            margin-top: 16px;

            h6 {
                color: #707070;
                text-align: center;

                font-family: "Avenir Next LT Pro";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.72px;
            }
        }

        .card-paragraph {
            margin-top: 25px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
            // max-width: 333px;

            span {
                color: #707070;
                text-align: center;

                font-family: "Avenir Next LT Pro";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.72px;
            }
        }

        .card-socialmedia {
            display: flex;
            gap: 20px;
            margin-top: 25px;
        }


    }
}

@media only screen and (max-width: 600px) {
    .teamcard {
        width: 333px;

        .teamcard-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .card-title {
                margin-top: 10px;

                h6 {
                    color: #2B2B2B;

                    font-family: "Readex Pro";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .card-subtitle {
                margin-top: 10px;

                h6 {
                    color: #707070;
                    text-align: center;

                    font-family: "Avenir Next LT Pro";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.72px;
                }
            }

            .card-paragraph {
                margin-top: 10px;
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
                // max-width: 333px;

                span {
                    color: #707070;
                    text-align: center;

                    font-family: "Avenir Next LT Pro";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.72px;
                }
            }

            .card-socialmedia {
                display: flex;
                gap: 20px;
                margin-top: 20px;
            }


        }
    }
}