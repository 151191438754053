.heroSection-main {
    width: 100%;
    position: relative;
    overflow: hidden;

    .herosec-primary {
        width: 100%;
        height: calc(100vh - 100px);
        // background: #FAFDFF;
        margin-top: 100px;
        // border: 3px solid green;
        background: #F5F5F5;


        .herosection-content {
            width: 100%;
            height: 100%;
            display: flex;

            .leftside {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                // padding: 248px 0px 267px 228px;
                padding-left: 228px;

                .leftContent {
                    max-width: 781px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;


                    h5 {
                        color: var(--dark-blue, #143A62);
                        width: 100%;
                        font-family: "Avenir Next LT Pro";
                        font-size: 76px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                        letter-spacing: -3.04px;

                        span {
                            color: var(--dark-blue, #0BD);

                            font-family: "Avenir Next LT Pro";
                            font-size: 76px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: normal;
                            letter-spacing: -3.04px;
                        }


                    }

                    p {
                        color: #2B2B2B;

                        font-family: "Avenir Next LT Pro";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }

                    .button-parent {
                        display: flex;
                        gap: 32px;

                        .learn-more {
                            padding: 16px 32px;
                            border-radius: 8px;
                            background: var(--dark-blue, #143A62);
                            color: #FFF;
                            font-family: "Avenir Next LT Pro";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .signup {
                            padding: 16px 32px;
                            border-radius: 8px;
                            border: 1.333px solid var(--dark-blue, #143A62);
                            background: #FAFDFF;
                            color: var(--dark-blue, #143A62);
                            font-family: "Avenir Next LT Pro";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    // button {
                    //     border-radius: 8px;
                    //     background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                    //     padding: 16px 32px;
                    //     width: 154px;
                    //     border: none;
                    //     cursor: pointer;
                    //     color: #FFF;
                    //     font-family: "Inter";
                    //     font-size: 1.146vw;
                    //     font-style: normal;
                    //     font-weight: 500;
                    //     line-height: normal;
                    //     text-transform: capitalize;
                    // }
                }
            }

            .rightside {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                position: relative;
                padding-right: 160px;

                // .rightcontent {

                //     display: flex;
                //     gap: 30px;


                //     .righleft-content {
                //         display: flex;
                //         flex-direction: column;
                //         gap: 30px;
                //     }

                //     .rightright-content {
                //         display: flex;
                //         flex-direction: column;
                //         gap: 30px;
                //     }

                //     // img {
                //     //     width: 100%;
                //     //     height: 100%;
                //     //     object-fit: cover;
                //     // }
                // }

                .rightcontent {
                    display: flex;
                    gap: 30px;



                    .blur-section {
                        background: linear-gradient(180deg, #F5F5F5 18.18%, rgba(245, 245, 245, 0.00) 100%);
                        height: 100px;
                        // background: black;
                        width: 100%;
                        position: absolute;
                        top: 0px;
                        // left: 0px;
                        // right: 0px;
                        z-index: 3;


                        // img {
                        //     width: 100%;
                        //     height: 100%;
                        // }

                    }

                    .blur-section2 {
                        background: linear-gradient(0deg, #F5F5F5 18.18%, rgba(245, 245, 245, 0) 100%);
                        // background: linear-gradient(180deg, #F5F5F5 18.18%, rgba(245, 245, 245, 0.00) 100%);
                        height: 100px;
                        // background: black;
                        width: 100%;
                        position: absolute;
                        bottom: 0px;
                        // left: 0px;
                        // right: 0px;
                        z-index: 3;
                        // border: 1px solid red;
                        // opacity: 0.3;


                        // border: 1px solid red;

                        // img {
                        //     width: 100%;
                        //     height: 100%;
                        // }

                    }
                }

                .righleft-content,
                .rightright-content {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    overflow: hidden;



                }

                .righleft-content img {
                    width: 100%;
                    height: auto;
                    animation: slideFromTop 8s linear infinite;
                    /* Adjusted duration */
                }

                .rightright-content img {
                    width: 100%;
                    height: auto;
                    animation: slideFromBottom 8s linear infinite;
                    /* Adjusted duration */
                }

                @keyframes slideFromTop {

                    0%,
                    50%,
                    100% {
                        transform: translateY(-50%);
                    }

                    25%,
                    75% {
                        transform: translateY(50%);
                    }
                }

                @keyframes slideFromBottom {

                    0%,
                    50%,
                    100% {
                        transform: translateY(50%);
                    }

                    25%,
                    75% {
                        transform: translateY(-50%);
                    }
                }



            }
        }
    }

    .herotopimg {
        position: absolute;
        top: 0;
        left: 0;
        // right: 0;
        // width: 10%;
        height: 100%;
        background-repeat: no-repeat;
        z-index: 1;

    }

    .herobottomimg {
        position: absolute;
        top: 0;
        // bottom: -60%;
        z-index: 10;
        right: 0;
        z-index: 1;
        height: 100%;



    }

}

@media only screen and (max-width: 1600px) {
    .heroSection-main {
        width: 100%;
        position: relative;
        overflow: hidden;

        .herosec-primary {
            width: 100%;
            height: calc(100vh - 100px);
            // background: #FAFDFF;
            margin-top: 100px;
            // border: 3px solid green;


            .herosection-content {
                width: 100%;
                height: 100%;
                display: flex;

                .leftside {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    // padding: 248px 0px 267px 228px;
                    padding-left: 60px;

                    .leftContent {
                        max-width: 781px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;


                        h5 {
                            color: var(--dark-blue, #143A62);
                            width: 100%;
                            font-family: "Avenir Next LT Pro";
                            font-size: 76px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -3.04px;

                            span {
                                color: var(--dark-blue, #0BD);

                                font-family: "Avenir Next LT Pro";
                                font-size: 76px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: -3.04px;
                            }


                        }

                        p {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.88px;
                        }

                        .button-parent {
                            display: flex;
                            gap: 32px;

                            .learn-more {
                                padding: 16px 32px;
                                border-radius: 8px;
                                background: var(--dark-blue, #143A62);
                                color: #FFF;
                                font-family: "Avenir Next LT Pro";
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;
                            }

                            .signup {
                                padding: 16px 32px;
                                border-radius: 8px;
                                border: 1.333px solid var(--dark-blue, #143A62);
                                background: #FAFDFF;
                                color: var(--dark-blue, #143A62);
                                font-family: "Avenir Next LT Pro";
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        // button {
                        //     border-radius: 8px;
                        //     background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                        //     padding: 16px 32px;
                        //     width: 154px;
                        //     border: none;
                        //     cursor: pointer;
                        //     color: #FFF;
                        //     font-family: "Inter";
                        //     font-size: 1.146vw;
                        //     font-style: normal;
                        //     font-weight: 500;
                        //     line-height: normal;
                        //     text-transform: capitalize;
                        // }
                    }
                }

                .rightside {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    padding-right: 40px;

                    .rightcontent {
                        // padding: 50px;

                        // max-width: 704px;
                        // max-width: 565px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        .blur-section {
                            background: linear-gradient(180deg, #F5F5F5 18.18%, rgba(245, 245, 245, 0.00) 100%);
                            height: 50px;
                            // background: black;
                            width: 100%;
                            position: absolute;
                            top: 0px;
                            // left: 0px;
                            // right: 0px;
                            z-index: 3;


                            // img {
                            //     width: 100%;
                            //     height: 100%;
                            // }

                        }

                        .blur-section2 {
                            background: linear-gradient(0deg, #F5F5F5 18.18%, rgba(245, 245, 245, 0) 100%);
                            // background: linear-gradient(180deg, #F5F5F5 18.18%, rgba(245, 245, 245, 0.00) 100%);
                            height: 50px;
                            // background: black;
                            width: 100%;
                            position: absolute;
                            bottom: 0px;
                            // left: 0px;
                            // right: 0px;
                            z-index: 3;
                            // border: 1px solid red;
                            // opacity: 0.3;


                            // border: 1px solid red;

                            // img {
                            //     width: 100%;
                            //     height: 100%;
                            // }

                        }

                        .righleft-content {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            justify-content: center;
                            align-items: center;
                            // max-width: 30%;



                        }

                        .rightright-content {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            justify-content: center;
                            align-items: center;
                            // max-width: 30%;
                        }

                        // img {
                        //     width: 100%;
                        //     height: 100%;
                        //     object-fit: cover;
                        // }
                    }
                }
            }
        }

        .herotopimg {
            position: absolute;
            top: 0;
            left: 0;
            // right: 0;
            width: 7%;
            height: 100%;
            background-repeat: no-repeat;
            z-index: 1;

        }

        .herobottomimg {
            position: absolute;
            top: 0;
            // bottom: -60%;
            z-index: 10;
            right: 0;
            z-index: 1;
            height: 100%;
            width: 7%;



        }

    }
}

@media only screen and (max-width: 1300px) {
    .heroSection-main {
        width: 100%;
        position: relative;
        overflow: hidden;

        .herosec-primary {
            width: 100%;
            height: calc(100vh - 100px);
            // background: #FAFDFF;
            margin-top: 100px;
            // border: 3px solid green;


            .herosection-content {
                width: 100%;
                height: 100%;
                display: flex;

                .leftside {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    // padding: 248px 0px 267px 228px;
                    padding-left: 60px;

                    .leftContent {
                        max-width: 781px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;


                        h5 {
                            color: var(--dark-blue, #143A62);
                            width: 100%;
                            font-family: "Avenir Next LT Pro";
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: normal;
                            letter-spacing: -0.2px;

                            span {
                                color: var(--dark-blue, #0BD);

                                font-family: "Avenir Next LT Pro";
                                font-size: 40px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                letter-spacing: -0.2px;
                            }


                        }

                        p {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 19px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.1px;
                        }

                        .button-parent {
                            display: flex;
                            gap: 10px;

                            .learn-more {
                                padding: 16px 25px;
                                border-radius: 8px;
                                background: var(--dark-blue, #143A62);
                                color: #FFF;
                                font-family: "Avenir Next LT Pro";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;
                            }

                            .signup {
                                padding: 16px 25px;
                                border-radius: 8px;
                                border: 1.333px solid var(--dark-blue, #143A62);
                                background: #FAFDFF;
                                color: var(--dark-blue, #143A62);
                                font-family: "Avenir Next LT Pro";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        // button {
                        //     border-radius: 8px;
                        //     background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                        //     padding: 16px 32px;
                        //     width: 154px;
                        //     border: none;
                        //     cursor: pointer;
                        //     color: #FFF;
                        //     font-family: "Inter";
                        //     font-size: 1.146vw;
                        //     font-style: normal;
                        //     font-weight: 500;
                        //     line-height: normal;
                        //     text-transform: capitalize;
                        // }
                    }
                }

                .rightside {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    padding-right: 40px;

                    .rightcontent {
                        // padding: 50px;

                        // max-width: 704px;
                        // max-width: 565px;
                        display: flex;
                        gap: 10px;


                        .righleft-content {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                        }

                        .rightright-content {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                        }

                        // img {
                        //     width: 100%;
                        //     height: 100%;
                        //     object-fit: cover;
                        // }
                    }
                }
            }
        }

        .herotopimg {
            position: absolute;
            top: 0;
            left: 0;
            // right: 0;
            width: 5%;
            height: 100%;
            background-repeat: no-repeat;
            z-index: 1;

        }

        .herobottomimg {
            position: absolute;
            top: 0;
            // bottom: -60%;
            z-index: 10;
            right: 0;
            z-index: 1;
            height: 100%;
            width: 5%;



        }

    }
}

@media only screen and (max-width: 600px) {
    .heroSection-main {
        width: 100%;
        position: relative;
        overflow: hidden;

        .herosec-primary {
            width: 100%;
            height: 100%;
            // height: calc(100vh - 100px);
            // background: #FAFDFF;
            margin-top: 100px;
            // border: 3px solid green;


            .herosection-content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .leftside {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    // padding: 248px 0px 267px 228px;
                    padding-left: 10px;


                    .leftContent {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        text-align: center;


                        h5 {
                            color: var(--dark-blue, #143A62);
                            width: 100%;
                            font-family: "Avenir Next LT Pro";
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: normal;
                            letter-spacing: -0.04px;

                            span {
                                color: var(--dark-blue, #0BD);

                                font-family: "Avenir Next LT Pro";
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 900;
                                line-height: normal;
                                letter-spacing: -0.04px;
                            }


                        }

                        p {
                            color: #2B2B2B;

                            font-family: "Avenir Next LT Pro";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: -0.18px;
                        }

                        .button-parent {
                            display: flex;
                            gap: 10px;

                            .learn-more {
                                padding: 10px 15px;
                                border-radius: 8px;
                                background: var(--dark-blue, #143A62);
                                color: #FFF;
                                font-family: "Avenir Next LT Pro";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;

                            }

                            .signup {
                                padding: 10px 15px;
                                border-radius: 8px;
                                border: 1.333px solid var(--dark-blue, #143A62);
                                background: #FAFDFF;
                                color: var(--dark-blue, #143A62);
                                font-family: "Avenir Next LT Pro";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        // button {
                        //     border-radius: 8px;
                        //     background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                        //     padding: 16px 32px;
                        //     width: 154px;
                        //     border: none;
                        //     cursor: pointer;
                        //     color: #FFF;
                        //     font-family: "Inter";
                        //     font-size: 1.146vw;
                        //     font-style: normal;
                        //     font-weight: 500;
                        //     line-height: normal;
                        //     text-transform: capitalize;
                        // }
                    }
                }

                .rightside {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    padding-right: 10px;

                    .rightcontent {
                        // padding: 50px;

                        // max-width: 704px;
                        // max-width: 565px;
                        display: flex;
                        gap: 5px;


                        .righleft-content {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        }

                        .rightright-content {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        }

                        // img {
                        //     width: 100%;
                        //     height: 100%;
                        //     object-fit: cover;
                        // }
                    }
                }
            }
        }

        .herotopimg {
            display: none;

        }

        .herobottomimg {
            display: none;



        }

    }
}