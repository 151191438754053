.performanceIndicatorContainer{
    height: 150px;
    border: 1px solid black;
    margin-top: 20px;
    padding: 5px;
    .forH1{
        height: 10%;
      }
    .performanceIndicatorLightsContainer{
        display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90%;
    .indicator{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
        .performanceIndicator{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: mediumspringgreen;

        }
    }
    

}

// .performanceIndicator{
//     animation-name: blink;
//     animation-duration: 0.5s;
//     animation-iteration-count: infinite;

// }


// @keyframes blink {
// from{
//     opacity: 0;
// }
// to{
//     opacity: 1;

// }   
// }

.noBreakForPrint{
    page-break-inside: avoid;
}