.extrazipfile-container {
    width: 100%;
    padding: 20px;

    .extrazipfile-box {
        display: flex;
        align-items: center;
        max-width: 500px;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: #fff;
        border: 3px solid #fff;
        cursor: pointer; 

        &:hover {
            border: 3px solid #57AEDC;
        }

        &.selected {
            border: 3px solid #3d7a9b; 
        }

        .extrazipfile-icon {
            width: 40px;
            height: 40px;
        }

        p {
            font-size: 20px;
            color: #262626;
            font-weight: 500;
            margin-left: 5px;

            span {
                color: #57AEDC;
            }
        }
    }
}

.upload-button{
    
}